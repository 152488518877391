import React from "react";

const TodoItem = props => {
  return (
<div className="card border-primary m-3" style={{maxWidth: "18rem"}}>
  <div className="card-header">  <button className="btn btn-danger" onClick={() => props.handleClick(props.item._id)}> X </button></div>
  <div className="card-body text-primary">
    <h5 className="card-title">{props.item.name}</h5>
    <p className="card-text"></p>
  </div>
</div>
  );
};

export default TodoItem;
