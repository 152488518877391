import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
     
        <nav className="navbar fixed-bottom navbar-light bg-light">
          <div className="container-fluid d-flex justify-content-center"> 
             {/* {loader-placeholder} */}
            <div id="spinner"><div></div><div></div><div></div><div></div></div>
               {/* {toast-message-placeholder} */}
            <div
            id="toast"
            className="m-2 px-2"
            style={{
              padding: "2px",
              backgroundColor: "lightgreen",
              border: "1px black solid",
              display: "none"
            }}
          >
            {this.props.message}
          </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Footer;
