export default {
  API: {
    GET_TODO:
      "https://data.mongodb-api.com/app/torrent_trak-callp/endpoint/todo-app/get_todos?secret=SECRET",
    SAVE_TODO:
      "https://data.mongodb-api.com/app/torrent_trak-callp/endpoint/todo-app/save_todo?secret=SECRET",
    EDIT_TODO:
      "https://data.mongodb-api.com/app/torrent_trak-callp/endpoint/todo-app/edit_todo?secret=SECRET",
    DELETE_TODO:
      "https://data.mongodb-api.com/app/torrent_trak-callp/endpoint/todo-app/delete_todo?secret=SECRET"
  }
};
