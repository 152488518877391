import React, { Component } from "react";
import axios from "axios";
import TodoItem from "./todo-item";
import Footer from "./footer";
import Config from "../config";

class Home extends Component {
  state = {
    newtodoName: "",
    todoList: []
  };

  componentDidMount() {
    this.showFooter();
    this.handleGettodo();
  }

  hideFooter = () => {
    this.setState({ loading: false });
  }

  showFooter = () => {
    this.setState({ loading: true });
  }

  handleGettodo() {
    //returns a promise
    this.showFooter();
    return axios.get(Config.API.GET_TODO).then(response => {
      let newtodoItems = this.state.todoList.splice();
      newtodoItems = newtodoItems.concat(response.data.result);
      this.setState({ todoList: newtodoItems });
      this.hideFooter();
    });
  }

  handleDeletetodo = (id) => {
    this.showFooter();
    const queryParams = new URLSearchParams();
    queryParams.append('todo_id', id);

    var headers = {
      "Content-Type": "application/json"
    };

    axios
    .delete(Config.API.DELETE_TODO, { params: queryParams, headers: headers })
    .then(response => {
      console.log("DELETE respose", response);
      if (response.status === 200) {
        this.handleGettodo().then( response => {
          this.showToastMessage("Todo has been removed!!"); //refetches updated list
        }); 
       
      }
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    this.showFooter();
    const postBody = JSON.stringify({
      name: this.state.newtodoName
    });
    var headers = {
      "Content-Type": "application/json"
    };

    axios
      .post(Config.API.SAVE_TODO, postBody, { headers: headers })
      .then(response => {
        console.log("POST respose", response);
        if (response.status === 200) {
          this.handleGettodo().then( response => {
            this.showToastMessage("Todo has been added!!"); //refetches updated list
          }); 
        
        }
      });
  };

  showToastMessage(message) {
    let _this = this;
    this.showFooter();
    document.getElementById("toast").innerHTML = message;
    document.getElementById("toast").style.display = "inline";
    document.getElementById("spinner").style.display = "none";

    setTimeout(function() {
      document.getElementById("toast").style.display = "none";
      document.getElementById("spinner").style.display = "block";
      _this.hideFooter();
    }, 6000, _this);
  }

  handleInputChange = event => {
    this.setState({ newtodoName: event.target.value });
  };

  render() {
    return (
      <React.Fragment>
         {(this.state.loading) ? <Footer/> : null}
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <input
              className="m-2"
              onChange={this.handleInputChange}
              type="text"
              name="title"
              placeholder="Add a todo.."
            />
            <button
              className="btn btn-primary btn-sm m-2"
              disabled={!this.state.newtodoName}
              type="submit"
            >
              {" "}
              Save Todo{" "}
            </button>
          </form>
          <div className="card-group m-2">
          {this.state.todoList.map(item => {

            if(item){
            return <div className="card-holder" key={item._id}>
          
              <TodoItem item={item}  handleClick={() => this.handleDeletetodo(item._id)}/>
              </div>
            } else{
              return <p className="lead" key="1">Yay! No Todos.</p>
            }
          })}
           </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
